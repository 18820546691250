import React, { Component } from 'react'

export class SliderComp extends Component {
  render() {
    const {image, span1, span2, width, width2} = this.props;
    //  slide-show-cont
    return (
        <div className='max-h-[auto]   relative min-w-[100%] slideTransition'>
          <img  className='w-full ' src={image}  />
          <div className='flex absolute inset-0  z-10 justify-end max-[600px]:justify-center items-center h-full container slide-show-cont '>

            <div className=' slidercomp p-[48px] bg-[#ffffff5e] mr-[50px]  rounded-[8px] flex flex-col gap-[8px] 
            max-w-[80%]  items-end  max-[1260px]:mr-[100px] 
            max-[600px]:max-w-[60%] max-[600px]:mr-0   max-[600px]:mt-[10%]   
            '>
              < span className= {` span1 max-w-[410px] text-[white] text-end text-[48px] leading-[60px] font-[900] `}>
                {span1}
              <br />
              </span >
              <span className={`max-w-[480px] span2 text-[#467C7C] text-end text-[48px] leading-[60px] font-[900]`} >
                {span2}
              </span>
            </div>
          </div>
        </div>
    )
  }
}



export default SliderComp