import React, { Component } from 'react'

import img1_1 from '../image/partnyorolarImages/img1-1.png'
import img1_2 from '../image/partnyorolarImages/img1-2.png'
import img1_3 from '../image/partnyorolarImages/img1-3.png'
import img1_4 from '../image/partnyorolarImages/img1-4.png'
import img1_5 from '../image/partnyorolarImages/img1-5.png'
import img1_6 from '../image/partnyorolarImages/img1-6.png'
import img1_7 from '../image/partnyorolarImages/img1-7.png'
import img1_8 from '../image/partnyorolarImages/img1-8.png'
import img1_9 from '../image/partnyorolarImages/img1-9.png'
import img1_10 from '../image/partnyorolarImages/img1-10.png'
import img1_11 from '../image/partnyorolarImages/img1-11.png'
import img1_12 from '../image/partnyorolarImages/img1-12.png'
import img2_1 from '../image/partnyorolarImages/img2-1.png'
import img2_2 from '../image/partnyorolarImages/img2-2.png'
import img2_3 from '../image/partnyorolarImages/img2-3.png'
import img2_4 from '../image/partnyorolarImages/img2-4.png'
import img2_5 from '../image/partnyorolarImages/img2-5.png'
import img2_6 from '../image/partnyorolarImages/img2-6.png'
import img2_7 from '../image/partnyorolarImages/img2-7.png'
import img2_8 from '../image/partnyorolarImages/img2-8.png'
import img2_9 from '../image/partnyorolarImages/img2-9.png'
import img2_10 from '../image/partnyorolarImages/img2-10.png'
import img2_11 from '../image/partnyorolarImages/img2-11.png'
import img2_12 from '../image/partnyorolarImages/img2-12.png'
import img3_1 from '../image/partnyorolarImages/img3-1.png'
import img3_2 from '../image/partnyorolarImages/img3-2.png'
import img3_3 from '../image/partnyorolarImages/img3-3.png'
import img3_4 from '../image/partnyorolarImages/img3-4.png'
import img3_5 from '../image/partnyorolarImages/img3-5.png'
import img3_6 from '../image/partnyorolarImages/img3-6.png'
import img3_7 from '../image/partnyorolarImages/img3-7.png'
import img3_8 from '../image/partnyorolarImages/img3-8.png'
import img3_9 from '../image/partnyorolarImages/img3-9.png'
import img3_10 from '../image/partnyorolarImages/img3-10.png'
import img3_11 from '../image/partnyorolarImages/img3-11.png'
import img3_12 from '../image/partnyorolarImages/img3-12.png'
 

export class Card2 extends Component {
  render() {
    return (
        <div style={{transition:'1.2s'}} className=' gap-[32px] flex-col p-[2px] mt-[32px] min-w-[100%]'>
            <div className='flex flex-col items-center gap-[16px] '>
                <h3 className='font-[700] text-[20px] leading-[24px] text-[var(--black)]'>
                    PARTNYORLARIMIZ
                </h3>
                <p className='font-[400] text-[16px] leading-[19.5px] text-[var(--black)] p-[16px_32px] resp3'>
                Şirkətimiz yarandığı gündən müştərisi olan tərəfdaşlarının daim yanında olmağı, onları yarana biləcək
                risklərdən qorumağı və yaranan problemləri həll etməyi özünə hədəf seçmişdir. Bunun nəticəsi olaraq müştəri
                loyallıq səviyyəsi 80% və ondan artıq olmuşdur. Mütəmadi əlaqə və ziyarətlər,
                vaxtında çatdırılma və peşəkar servis xidməti ilə daim müştərilərimizin xidmətindəyik.
                </p>
            </div>
            <div className='flex flex-wrap gap-[0.08px] mt-[22px]'>
                <img src={img1_1} />
                <img src={img1_2} />
                <img src={img1_3} />
                <img src={img1_4} />
                <img src={img1_5} />
                <img src={img1_6} />
                <img src={img1_7} />
                <img src={img1_8} />
                <img src={img1_9} />
                <img src={img1_10} />
                <img src={img1_11} />
                <img src={img1_12} />
                <img src={img2_1} />
                <img src={img2_2} />
                <img src={img2_3} />
                <img src={img2_4} />
                <img src={img2_5} />
                <img src={img2_6} />
                <img src={img2_7} />
                <img src={img2_8} />
                <img src={img2_9} />
                <img src={img2_10} />
                <img src={img2_11} />
                <img src={img2_12} />
                <img src={img3_1} />
                <img src={img3_2} />
                <img src={img3_3} />
                <img src={img3_4} />
                <img src={img3_5} />
                <img src={img3_6} />
                <img src={img3_7} />
                <img src={img3_8} />
                <img src={img3_9} />
                <img src={img3_10} />
                <img src={img3_11} />
                <img src={img3_12} />
            </div>
           
            
        </div>
    )
  }
}

export default Card2