import React, { Component } from 'react'
import logo from '../image/logo.png'
import HamburgerSvg from '../svg/HamburgerSvg'

export class Nav extends Component {
    componentDidMount(){
        window.addEventListener("scroll", (e)=>{
            if(window.scrollY <= 5 ){
                const nav1 = document.querySelector('.nav1')
                nav1.style.position = 'relative'
            }else{
                const nav1 = document.querySelector('.nav1')
                nav1.style.position = 'fixed'
            }
        })
    }
  render() {
    
    return (

        <nav className='bg-[#727272] nav1 w-full   hidden max-[600px]:block z-[1000px]   max-[600px]:relative'>
            <div className='container flex justify-between items-center p-[16px_0px_16px_0px] '>
                <img className='resp5'  src={logo} />
                <div className='menu1 max-[900px]:mt-[12px]'>
                    <input type='checkbox' id='onclick1' />

                    <label for='onclick1' className='lbl-resp1'>
                    <HamburgerSvg />
                    </label>


                    <ul className='flex gap-[32px] items-center'>
                        <li>
                            <a  
                            onClick={() => this.handleScrollToSection('products')} 
                            className='text-[var(--white)] font-[700] text-[16px] leading-[24px] cursor-pointer'>
                            MƏHSULLAR
                            </a>
                        </li>
                        <li>
                            <a 
                            onClick={() => this.handleScrollToSection('aboutus')}
                             className='text-[var(--white)] font-[700] text-[16px] leading-[24px] cursor-pointer'>
                            HAQQIMIZDA
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
  }
}

export default Nav