import React, { Component } from 'react'
import "react-multi-carousel/lib/styles.css";
import SliderComp from './SliderComp';

import slider1 from '../image/slider1.png'
import slider2 from '../image/slider2.png'
import slider3 from '../image/slider3.png'
import slider4 from '../image/slider4.png'
import slider5 from '../image/slider5.png'
import slider6 from '../image/slider6.png'
import slider7 from '../image/slider7.png'
import LeftArrow from '../svg/LeftArrow';
import RightArrow from '../svg/RightArrow';

export class HeaderSlider extends Component {
  state={
    contref: React.createRef(),
    index:0
  }
  sliderChange(index){
    const card=document.querySelectorAll('.slide-show-cont');
    
    for(let i = 0; i < card.length; i++){
      card[i].style.opacity = '0';
    }
   setTimeout(()=>{
    const container = this.state.contref?.current;
    for(let i = 0; i < container?.children.length; i++){
      container.children[i].style.transform = `translateX(-${container?.children[i].getBoundingClientRect()?.width*index}px)`
    }
   }, 400)
    setTimeout(()=>{
      for(let i = 0; i < card.length; i++){
        card[i].style.opacity = '1';
      }
    }, 1200)
  }
  componentDidMount(){
    let index = 0;
    setInterval(()=>{
      index = this.state.index;
     
      if(index < this.state.contref?.current?.children?.length-1){
        index++
      }else{
        index=0
      }
      this.sliderChange(index);
      this.setState({index:index})
    }, 7000)
  }

  changeSlide=(type)=>{
    console.log(type)
    let index = this.state.index;
    if(type === "left"){
      if(index >0){
        index--
      }else{
        index=this.state.contref?.current?.children?.length-1
      }
    }else{
      if(index < this.state.contref?.current?.children?.length-1){
        index++
      }else{
        index=0
      }
    }
    this.sliderChange(index);
      this.setState({index:index})
  }
  render() {
    const {contref} = this.state;
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 564 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 564, min: 0 },
          items: 1
        }
      };
    return (
    <div className='relative'>
      <div ref={contref} className='flex overflow-hidden items-center '>
        <SliderComp 
          image={slider1} 
          span1='Daha  təmiz Daha sağlam' 
          span2='Daha təhlükəsiz HƏYAT'
          
         
          />
          
        <SliderComp 
          image={slider2} 
          span1='İdeal '
          span2='TƏMİZLİK'
          
         />
        <SliderComp 
          image={slider3}
          span1='Effektivlik'
          span2='VƏ QƏNAƏT'
          />
        <SliderComp 
          image={slider4}
          span1='Risklərdən qorunmaq'
          span2='VƏ PROBLEMLƏRİN HƏLLİ'
          
        />
        <SliderComp 
          image={slider5}
          span1='Personal üçün'
           span2='TƏLİMLƏR'
          
        />
        <SliderComp 
          image={slider6}
          span1='Sürətli'
          span2='ÇATDIRILMA'
          
        />
        <SliderComp 
          image={slider7}
          span1='Peşəkar'
          span2='TEXNİKİ SERVİS'
         
        />

       
       
      </div>
      <div className='absolute z-[200] top-[50%] h-[30px] w-full  flex justify-between items-center p-[0_50px] max-[600px]:p-[0_30px]'>
        <button onClick={this.changeSlide.bind(this, "left")}>
          <LeftArrow width='60' height='60'/>

        </button>
        <button onClick={this.changeSlide.bind(this, "right")}>
          <RightArrow width='60' height='60'/>
        </button>
      </div>
    </div>
    )
  }
}

export default HeaderSlider