import React, { Component } from 'react'



export class Card1 extends Component {

  render(Props) {
    const {title, text1="", text2="", text3="", text4="", text5=""} = this.props;
    return (
      <div style={{transition:'1.2s'}} className='flex gap-[32px] p-[2px] justify-center items-start mt-[32px] min-w-[100%] max-[1200px]:flex-col max-[1200px]:justify-start'>
        {/* <div > */}
          <img className='max-w-full w-full' src={this.props.image} />
        {/* </div> */}

        <div className='flex flex-col pl-[3px] justify-between gap-[16px] max-w-[568px] mt-[16px] ' >
            <h3 className='text-[var(--black)] text-[20px] font-[700] leading-[24px] mb-[16px] '>
            {title}
            </h3>
            <span className='font-[400] text-[16px]  text-[var(--black)] leading-[19.5px]'>
                {text1}
            </span>
            <span className='font-[400] text-[16px] text-[var(--black)] leading-[19.5px]'>
                {text2}
            </span>
            {
              text3 !==""?
              <span className=' font-[400] text-[16px] text-[var(--black)] leading-[19.5px]'>
                {text3}
              </span>:null
            }
            
            {
              text4 !==""?
              <span className=' font-[400] text-[16px] text-[var(--black)] leading-[19.5px]'>     
                {text4}
              </span>:null
            }
            
            {
              text5 !==""?
              <span className=' font-[400] text-[16px] text-[var(--black)] leading-[19.5px]'>
                {text5}
              </span>:null
            }
            
        </div>
      </div>
    )
  }
}


export default Card1