import React, { Component } from 'react'
import card1 from '../image/card1.png'
import card2 from '../image/card2.png'
import card3 from '../image/card3.png'
import card4 from '../image/card4.png'
import card5 from '../image/card5.png'
import card6 from '../image/card6.png'
import card7 from '../image/card7.png'
import card8 from '../image/card8.png'

export class Products extends Component {
  render() {
    return (
        <section id='products' className='container p-[52px_0px_100px_0px]'>
            <div className='flex gap-[48px] items-center max-[550px]:flex-col'>
                <div className='flex gap-[12px] items-center pl-[16px] max-[550px]:pl-[0] max-[450px]:gap-[2px] '>
                    <svg width="48" height="48" className=' max-[450px]:width-[24]' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41.5669 37.4569L30 18.1725V7.5H31.5C31.8978 7.5 32.2793 7.34196 32.5606 7.06066C32.8419 6.77936 33 6.39782 33 6C33 5.60218 32.8419 5.22064 32.5606 4.93934C32.2793 4.65804 31.8978 4.5 31.5 4.5H16.5C16.1022 4.5 15.7206 4.65804 15.4393 4.93934C15.158 5.22064 15 5.60218 15 6C15 6.39782 15.158 6.77936 15.4393 7.06066C15.7206 7.34196 16.1022 7.5 16.5 7.5H18V18.1725L6.43311 37.4569C6.1603 37.9117 6.01294 38.4307 6.00605 38.961C5.99916 39.4913 6.13298 40.014 6.39387 40.4757C6.65476 40.9375 7.0334 41.3218 7.49121 41.5896C7.94901 41.8574 8.46962 41.999 8.99998 42H39C39.5308 42 40.0521 41.8591 40.5107 41.5917C40.9693 41.3244 41.3488 40.9401 41.6103 40.4782C41.8719 40.0163 42.0062 39.4932 41.9996 38.9624C41.9929 38.4316 41.8455 37.9121 41.5725 37.4569H41.5669ZM20.7862 19.3594C20.9265 19.1268 21.0004 18.8603 21 18.5887V7.5H27V18.5887C26.9995 18.8603 27.0734 19.1268 27.2137 19.3594L34.38 31.3125C32.13 31.7569 28.9294 31.5694 24.6769 29.4169C21.6937 27.9075 18.855 27.1069 16.1981 27.015L20.7862 19.3594ZM8.99998 39L14.3512 30.0788C17.0231 29.7525 20.0344 30.4256 23.3175 32.0887C26.88 33.8906 29.88 34.5037 32.3175 34.5037C33.5572 34.5092 34.7913 34.3388 35.9831 33.9975L39 39H8.99998Z" fill="#1994B0"/>
                    </svg>

                    <h2 className='font-[700] text-[24px] leading-[32px] text-[#444247] max-[450px]:text-[18px]'>
                        MƏHSULLAR
                    </h2>
                </div>
                <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue%20mastering_.pdf" download={true} target='_blank'>
                    <div className='cursor-pointer flex items-center gap-[16px] p-[12px_16px] border-[1px] border-[var(--blue)] rounded-[8px]' >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28 19V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H6C5.46957 28 4.96086 27.7893 4.58579 27.4142C4.21071 27.0391 4 26.5304 4 26V19C4 18.7348 4.10536 18.4804 4.29289 18.2929C4.48043 18.1054 4.73478 18 5 18C5.26522 18 5.51957 18.1054 5.70711 18.2929C5.89464 18.4804 6 18.7348 6 19V26H26V19C26 18.7348 26.1054 18.4804 26.2929 18.2929C26.4804 18.1054 26.7348 18 27 18C27.2652 18 27.5196 18.1054 27.7071 18.2929C27.8946 18.4804 28 18.7348 28 19ZM15.2925 19.7075C15.3854 19.8005 15.4957 19.8742 15.6171 19.9246C15.7385 19.9749 15.8686 20.0008 16 20.0008C16.1314 20.0008 16.2615 19.9749 16.3829 19.9246C16.5043 19.8742 16.6146 19.8005 16.7075 19.7075L21.7075 14.7075C21.8004 14.6146 21.8741 14.5043 21.9244 14.3829C21.9747 14.2615 22.0006 14.1314 22.0006 14C22.0006 13.8686 21.9747 13.7385 21.9244 13.6171C21.8741 13.4957 21.8004 13.3854 21.7075 13.2925C21.6146 13.1996 21.5043 13.1259 21.3829 13.0756C21.2615 13.0253 21.1314 12.9994 21 12.9994C20.8686 12.9994 20.7385 13.0253 20.6171 13.0756C20.4957 13.1259 20.3854 13.1996 20.2925 13.2925L17 16.5863V5C17 4.73478 16.8946 4.48043 16.7071 4.29289C16.5196 4.10536 16.2652 4 16 4C15.7348 4 15.4804 4.10536 15.2929 4.29289C15.1054 4.48043 15 4.73478 15 5V16.5863L11.7075 13.2925C11.5199 13.1049 11.2654 12.9994 11 12.9994C10.7346 12.9994 10.4801 13.1049 10.2925 13.2925C10.1049 13.4801 9.99944 13.7346 9.99944 14C9.99944 14.2654 10.1049 14.5199 10.2925 14.7075L15.2925 19.7075Z" fill="black"/>
                        </svg>

                        <span className='text-[var(--blue)] text-[16px] font-[500] leading-[24px]'>
                            Tam kataloqu yüklə
                        </span>
                    </div>
                </a>
                
                
            </div>

            <div className='grid grid-268 gap-[32px] mt-[52px] '>
              
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_kitchen_v1_compressed.pdf" target='_blank' className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card1} className='rounded-[8px]' />
                        <h4 className='text-[var(--black)] font-[600] text-[20px] leading-[24px] text-center mt-[16px] max-[450px]:text-[18px]'>
                            Mətbəx 
                        </h4> 
                    </a>
                

             
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_house_keeping_v1_compressed.pdf" target="_blank" className=' cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card2} className=' rounded-[8px]' />
                        <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                        Bina və şəxsi gigiyena
                        </h4>
                    </a>
               
                
              
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_laundry_v1_compressed.pdf" target='_blank' className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card3} className=' rounded-[8px]' />
                        <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                        Camaşırxana
                        </h4>
                    </a>
                
                
                
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_pull_v1_compressed.pdf" target="_blank" className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card4} className=' rounded-[8px]' />
                        <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                        Hovuz
                        </h4>
                    </a>
                

              
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_industry_v1_compressed.pdf" target="_blank" className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card5} className=' rounded-[8px]' />
                        <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                        Sənaye 
                        </h4>
                    </a>
            
                
                
                    <a href='https://srchemicalsplus.com/files/sr%20chemicals_catalogue_water%20chemicals_v1_compressed.pdf' target='_blank' className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card6} className=' rounded-[8px]' />
                        <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                        Suyun təmizlənməsi
                        </h4>
                    </a>
                
                
                
                    <a href="https://srchemicalsplus.com/files/sr%20chemicals_catalogue_food_v1_compressed.pdf" target='_blank' className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card7} className=' rounded-[8px] w-full' />
                        <div className='flex justify-center'>
                            <h4 className='text-[var(--black)] max-[450px]:text-[18px] font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                            Qida sənayesi
                            </h4>
                        </div>
                        
                    </a>
                
                
                
                    <a href="https://srchemicalsplus.com/files/s%C9%99rfiyyat%20mallar%C4%B1%20v%C9%99%20avadanl%C4%B1qlar_compressed.pdf" target='_blank' className='cursor-pointer bg-[var(--white)] rounded-[8px]  flex flex-col  p-[16px_16px_40px_16px] max-[450px]:p-[10px_10px_24px_10px]'>
                        <img src={card8} className=' rounded-[8px]' />
                        <div className='flex justify-center'>
                            <h4 className=' max-w-[167px] max-[450px]:text-[18px] text-[var(--black)]  font-[600] text-[20px] leading-[24px] text-center mt-[16px]'>
                            Sərfiyyat malları
                            və avadanlıqlar
                            </h4>
                        </div>
                    </a>
                
            </div>
    </section>
    )
  }
}

export default Products