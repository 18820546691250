import React, { Component } from 'react'
import Page from './components/Page'

export class App extends Component {
  render() {
    return (
      <div>
        <Page />
      </div>
    )
  }
}

export default App