import React, { Component } from 'react'
import logo from '../image/logo.png'
import HamburgerSvg from '../svg/HamburgerSvg'

export class Nav extends Component {
    handleScrollToSection = (sectionId) => {
        const sec = document.querySelector(`#${sectionId}`);
        const topPosition = sec.offsetTop;
       
        if(window.screen.width <=500){
            window.scroll(0, topPosition - 72); 
        }else{
            window.scroll(0, topPosition - 92); 

        }
      }


  render() {
    
    return (

        <nav className='bg-[#00000033]  w-full fixed navbar top-[48px] max-[600px]:hidden  z-[1000px] '>
            <div className='container flex justify-between items-center p-[16px_0px_16px_0px] '>
                <img className='resp5'  src={logo} />
                <div className='menu max-[900px]:mt-[12px]'>
                    <input type='checkbox' id='onclick' />

                    <label for='onclick' className='lbl-resp'>
                    <HamburgerSvg />
                    </label>


                    <ul className='flex gap-[32px] items-center'>
                        <li>
                            <a  
                            onClick={() => this.handleScrollToSection('products')} 
                            className='text-[var(--white)] font-[700] text-[16px] leading-[24px] cursor-pointer'>
                            MƏHSULLAR
                            </a>
                        </li>
                        <li>
                            <a 
                            onClick={() => this.handleScrollToSection('aboutus')}
                             className='text-[var(--white)] font-[700] text-[16px] leading-[24px] cursor-pointer'>
                            HAQQIMIZDA
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
  }
}

export default Nav