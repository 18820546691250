import React, { Component } from 'react'

export class LeftArrow extends Component {
  render() {
    const {width, height} = this.props
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    className="icon max-[600px]:w-[35px] max-[600px]:h-[35px]"
    viewBox="0 0 1024 1024"
    fill="#fff"
    
  >
    <path d="M768 903.232 717.568 960 256 512 717.568 64 768 120.768 364.928 512z" />
  </svg>
    )
  }
}

export default LeftArrow