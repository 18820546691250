import React, { Component } from 'react'

export class RightArrow extends Component {
  render() {
    const {width, height} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        className="icon max-[600px]:w-[35px] max-[600px]:h-[35px]"
        viewBox="0 0 1024 1024"
        fill="#fff"
    >
        <path d="M256 120.768 306.432 64 768 512 306.432 960 256 903.232 659.072 512z" />
    </svg>
    )
  }
}

export default RightArrow